import { Link, useLocation } from "@remix-run/react";
import { ArrowLeft } from "lucide-react";
import { GeneralErrorBoundary } from "~/my-components/GeneralErrorBoundary";
import { routes } from "~/utils/routes";

export async function loader() {
  throw new Response("Not found", { status: 404 });
}

export default function NotFound() {
  // due to the loader, this component will never be rendered, but we'll return
  // the error boundary just in case.
  return <ErrorBoundary />;
}

export function ErrorBoundary() {
  const location = useLocation();
  return (
    <GeneralErrorBoundary
      statusHandlers={{
        404: () => (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <h1>We can't find this page:</h1>
              <pre className="whitespace-pre-wrap break-all text-body-lg">
                {location.pathname}
              </pre>
            </div>
            <Link
              to={routes.home}
              className="text-body-md underline gap-2 flex items-center"
              prefetch="intent"
            >
              <ArrowLeft size="18" /> Back to home
            </Link>
          </div>
        ),
      }}
    />
  );
}
